import React, {Component} from 'react';

class TriNavbar extends Component {
    render() {
        return(
            <div className="navbar bg-base-100">
                <div className="navbar-start">
                    <a className="mr-4 ml-4">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.78 335.01" height="30px">
                        <polygon className="cls-3" fill="#8b2634" points="193.83 104.5 163.5 51.98 30.31 282.68 60.52 335.01 60.74 335.01 91.04 282.51 121.35 230.01 121.36 230.02 193.81 104.52 193.82 104.52 193.83 104.5"/>
                                <polyline className="cls-1" fill="#ec1c2b" points="133.48 0 132.89 .01 .09 230.01 .1 230.02 0 230.18 30.31 282.68 163.5 51.98 133.49 .01"/>
                                <polygon className="cls-5" fill="#3c2e62" points="91.04 282.51 357.73 282.51 366.87 266.67 387.58 230.8 387.12 230.01 266.28 230.02 266.27 230.02 121.36 230.01 121.35 230.01 91.04 282.51"/>
                                <polygon className="cls-6" fill="#4c2e86" points="60.74 335.01 327.42 335.01 357.73 282.51 91.04 282.51 60.74 335.01"/>
                                <polygon className="cls-2" fill="#8b2167" points="387.58 230.8 387.78 230.48 254.72 0 254.15 .01 194.1 .01 326.89 230.01 387.12 230.01 387.58 230.8"/>
                                <polygon className="cls-4" fill="#5c2852" points="194.11 .01 193.51 .01 133.49 .01 163.5 51.98 193.83 104.5 193.82 104.52 266.27 230.02 326.9 230.01 194.11 .01"/>
                        </svg>
                    </a>
                    <a className="hidden lg:block text-xl">Tri-County Residential Contractors, LLC</a>
                </div>
                <div className="navbar-end">
                <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>

                        </label>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li tabIndex={0}>
                                <a>
                                    Services
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                                </a>
                                <ul className="p-2 bg-base-100 right-full left-auto mr-3">
                                    <li><a>Service 1</a></li>
                                    <li><a>Service 2</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <ul className="menu menu-horizontal p-0 hidden lg:flex">
                        <li><a>Home</a></li>
                        <li tabIndex={0}>
                            <a>
                                Services
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                            </a>
                            <ul className="p-2 bg-base-100">
                                <li><a>Service 1</a></li>
                                <li><a>Service 2</a></li>
                            </ul>
                        </li>
                        <li>
                            <a>Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default TriNavbar;