import { Component } from "react"
const services = [
  { name: 'Gas or oil-fired warm air furnaces', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.'},
  { name: 'Gas or oil-fired hot water boilers', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Air conditioning', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Heat pumps', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Mini split heat pumps or air conditioners', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Full ductwork services', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Air filtration and purification', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Water filtration and purification', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Well water services from the pump to the faucet', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Sump pumps and sewage ejectors', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
  { name: 'Plumbing services', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.' },
]
  
class TriServices extends Component {
    render() {
        return (
      <div className="bg-white">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Services</h2>
            <p className="mt-4 text-gray-500">
              We offer a wide variety of mechanical services for the residential market. We can install or perform repairs to the following:
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {services.map((service) => (
                <div key={service.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">{service.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{service.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/QHYMBjUAYor2e7QBr2ug1Q/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/qtGO2uZ9lsSl79o9IFXgXA/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/Q1G-qTnI_-zfVC6tQkjtUQ/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/YDU-kh5SvfK12WeLf-9lBA/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/ShidnWi6mCD2O-9NmK3qNA/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/sKWLLrLGimgtomzKq0J37A/o.jpg"
              alt=""
              className="rounded-lg bg-gray-100"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TriServices;