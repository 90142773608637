import { GlobeAltIcon,BoltIcon,CostIcon,WrenchScrewDriverIcon } from "./svgs";
import React, {Component, createRef } from "react";

const features = [
  {
    name: 'Factory trained',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Licensed and fully insured',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: CostIcon,
  },
  {
    name: 'OSHA  30 qualified',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: BoltIcon,
  },
  {
    name: 'Veteran owned',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: WrenchScrewDriverIcon,
  },
  {
    name: 'Family business',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: WrenchScrewDriverIcon,
  },
]

class TriFeature extends Component {
    constructor(props) {
      super(props);
      this.featureRef = React.createRef();
    }
    render() {
        return (
          <div className="bg-white py-12" ref={this.featureRef}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                  {/* <h2 className="text-lg font-semibold text-indigo-600">Merits</h2> */}
                  <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                      Merits
                  </p>
                  <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                    Serving Philadelphia, Montgomery, & Bucks Counties
                  </p>
                </div>

                <div className="mt-10">
                <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                    {features.map((feature) => (
                    <div key={feature.name} className="relative">
                        <dt>
                        <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                            <feature.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                    </div>
                    ))}
                </dl>
                </div>
            </div>
          </div>
        )
    }
}

export default TriFeature;