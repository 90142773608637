import * as React from 'react';
import TriNavbar from './components/navbar';
import TriContent from './section/content';
import TriFooter from './section/footer';
import "tailwindcss/tailwind.css"

function App() {
  return (
    <div>
      <TriNavbar></TriNavbar>
      <TriContent></TriContent>
      <TriFooter></TriFooter>
    </div>
  );
}

export default App;
