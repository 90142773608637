import React, {Component} from 'react';
import TriContact from '../components/contact';
import TriFeature from '../components/feature';
import TriHero from '../components/hero';
import TriServices from '../components/services';

class TriContent extends Component {
    render() {
        return(
            <div>
                <TriHero></TriHero>
                <TriFeature></TriFeature>
                <TriServices></TriServices>
                <TriContact></TriContact>
            </div>
        );
    }
}

export default TriContent;