import React, {Component} from 'react';

class TriFooter extends Component {
    render() {
        return(
            <footer className="footer footer-center p-10 bg-base-200 text-base-content rounded">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.78 335.01" width="50">
                        <polygon fill="#aeaeae" className="cls-4" points="193.83 104.5 163.5 51.98 30.31 282.68 60.52 335.01 60.74 335.01 91.04 282.51 121.35 230.01 121.36 230.02 193.81 104.52 193.82 104.52 193.83 104.5"/>
                        <polygon fill="#fff" className="cls-1" points="133.48 0 132.89 .01 .09 230.01 .1 230.02 0 230.18 30.31 282.68 163.5 51.98 133.49 .01 133.48 0"/>
                        <polygon fill="#848484" className="cls-2" points="91.04 282.51 357.73 282.51 366.87 266.67 387.58 230.8 387.12 230.01 266.28 230.02 266.27 230.02 121.36 230.01 121.35 230.01 91.04 282.51"/>
                        <polygon fill="#dbdada" className="cls-1" points="60.74 335.01 327.42 335.01 357.73 282.51 91.04 282.51 60.74 335.01"/>
                        <polygon fill="#525252" className="cls-1" points="387.58 230.8 387.78 230.48 254.72 0 254.15 .01 194.1 .01 326.89 230.01 387.12 230.01 387.58 230.8"/>
                        <polygon fill="#b3b3b3" className="cls-3" points="194.11 .01 193.51 .01 133.49 .01 163.5 51.98 193.83 104.5 193.82 104.52 266.27 230.02 326.9 230.01 194.11 .01"/>
                    </svg>
                </div>
                <div>
                    <div className="grid grid-flow-col gap-4">
                    <a href="https://www.yelp.com/biz/tri-county-residential-contractors-blue-bell-2" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"  className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                        <path xmlns="http://www.w3.org/2000/svg" d="M20.16 12.594l-4.995 1.433c-.96.276-1.74-.8-1.176-1.63l2.905-4.308a1.072 1.072 0 0 1 1.596-.206 9.194 9.194 0 0 1 2.364 3.252 1.073 1.073 0 0 1-.686 1.459zm-5.025 3.152l4.942 1.606a1.072 1.072 0 0 1 .636 1.48 9.316 9.316 0 0 1-2.47 3.169 1.073 1.073 0 0 1-1.592-.26l-2.76-4.409c-.528-.847.288-1.894 1.236-1.584zm-4.796-4.368L5.454 2.916a1.072 1.072 0 0 1 .466-1.5A14.973 14.973 0 0 1 11.184.003a1.07 1.07 0 0 1 1.153 1.068v9.768c0 1.096-1.45 1.483-1.998.535zm-.857 4.17L4.44 16.806a1.073 1.073 0 0 1-1.324-.92 9.218 9.218 0 0 1 .43-3.997 1.07 1.07 0 0 1 1.485-.62l4.668 2.279c.9.438.763 1.76-.207 2zm.886 1.477c.669-.744 1.901-.246 1.866.752l-.19 5.188a1.073 1.073 0 0 1-1.247 1.02 9.314 9.314 0 0 1-3.722-1.502 1.072 1.072 0 0 1-.187-1.6l3.477-3.864z"/>
                        </svg>
                    </a> 
                    <a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-6 h-6"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a> 
                    <a><svg xmlns="http://www.w3.org/2000/svg"  className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                    </div>
                </div> 
                <div>
                    <p>Copyright © 2022 - All right reserved by Tri-County Residential Contractors, LLC</p>
                </div>
            </footer>
        );
    }
}

export default TriFooter;