import React, {Component, createRef} from 'react';

class TriHero extends Component {
    constructor(props) {
        super(props);
        this.heroRef = React.createRef();
    }
    render() {
        
        return(
            <div ref={this.heroRef} className="hero min-h-screen" style={{ backgroundImage: `url("https://images.unsplash.com/photo-1482731215275-a1f151646268?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")` }}>
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-lg">
                        <div className="flex justify-center mb-10">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.78 335.01" height="100px">
                                <polygon className="cls-3" fill="#8b2634" points="193.83 104.5 163.5 51.98 30.31 282.68 60.52 335.01 60.74 335.01 91.04 282.51 121.35 230.01 121.36 230.02 193.81 104.52 193.82 104.52 193.83 104.5"/>
                                <polyline className="cls-1" fill="#ec1c2b" points="133.48 0 132.89 .01 .09 230.01 .1 230.02 0 230.18 30.31 282.68 163.5 51.98 133.49 .01"/>
                                <polygon className="cls-5" fill="#3c2e62" points="91.04 282.51 357.73 282.51 366.87 266.67 387.58 230.8 387.12 230.01 266.28 230.02 266.27 230.02 121.36 230.01 121.35 230.01 91.04 282.51"/>
                                <polygon className="cls-6" fill="#4c2e86" points="60.74 335.01 327.42 335.01 357.73 282.51 91.04 282.51 60.74 335.01"/>
                                <polygon className="cls-2" fill="#8b2167" points="387.58 230.8 387.78 230.48 254.72 0 254.15 .01 194.1 .01 326.89 230.01 387.12 230.01 387.58 230.8"/>
                                <polygon className="cls-4" fill="#5c2852" points="194.11 .01 193.51 .01 133.49 .01 163.5 51.98 193.83 104.5 193.82 104.52 266.27 230.02 326.9 230.01 194.11 .01"/>
                            </svg>
                        </div>
                        <h1 className="text-5xl font-bold mb-10">Serving the Tri-County Area For Over 40 Years</h1>
                        <button className="btn btn-primary">Get Started</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TriHero;